body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@keyframes click-wave {
  0% {
    height: 30px;
    width: 30px;
    opacity: 0.35;
    position: relative;
 }
  100% {
    height: 200px;
    width: 200px;
    margin-left: -80px;
    margin-top: -80px;
    opacity: 0;
 }
}
.option-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 30px;
  width: 30px;
  transition: all 0.15s ease-out 0s;
  background: #cbd1d8;
  border: none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  outline: none;
  position: relative;
}
.option-input:hover {
  background: #9faab7;
}
.option-input.timetrue {
  background: #21c83d;
}
.option-input.half {
  background: #2e97ec;
}
.option-input.late {
  background: #efd732;
}
.option-input.absentask {
  background: #fd7a23;
}
.option-input.absent {
  background: #db0d18;
}
.option-input.checked::before {
  height: 30px;
  width: 30px;
  position: absolute;
  content: "✔";
  display: inline-block;
  font-size: 20px;
  text-align: center;
  line-height: 30px;
}
.option-input.checked::after {
  -webkit-animation: click-wave 0.65s;
  -moz-animation: click-wave 0.65s;
  animation: click-wave 0.65s;
  content: "";
  display: block;
  position: relative;
  z-index: 100;
}
.option-input.radio {
  border-radius: 50%;
}
.option-input.radio::after {
  border-radius: 50%;
}
/** * Main wrapper */
.select-search {
  position: relative;
  font-family: "Nunito Sans", sans-serif;
  box-sizing: border-box;
  border-radius: 5px;
  min-width: 200px;
}
.select-search *, .select-search *::after, .select-search *::before {
  box-sizing: inherit;
}
/** * Value wrapper */
.select-search__value {
  position: relative;
  z-index: 1;
}
.select-search__value::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: calc(50% - 9px);
  right: 19px;
  width: 11px;
  height: 11px;
}
/** * Input */
.select-search__input {
  display: block;
  height: 30px;
  width: 100%;
  background-color: #f0f1f3;
  padding: 0 5px;
  border: 1px solid transparent;
  border-radius: 3px;
  outline: none;
  font-family: "Noto Sans", sans-serif;
  font-size: 13px;
  text-align: left;
  text-overflow: ellipsis;
  line-height: 30px;
  -webkit-appearance: none;
}
.select-search__input::-webkit-search-decoration, .select-search__input::-webkit-search-cancel-button, .select-search__input::-webkit-search-results-button, .select-search__input::-webkit-search-results-decoration {
  -webkit-appearance: none;
}
.select-search__input:not([readonly]):focus {
  cursor: initial;
}
/** * Options wrapper */
.select-search__select {
  background: #fff;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
}
/** * Options */
.select-search__options {
  list-style: none;
  margin: 0;
  padding: 0;
}
/** * Option row */
.select-search__row:not(:first-child) {
  border-top: 1px solid #eee;
}
/** * Option */
.select-search__option, .select-search__not-found {
  display: block;
  height: 30px;
  width: 100%;
  padding: 0 10px;
  background: #fff;
  border: none;
  outline: none;
  font-family: "Noto Sans", sans-serif;
  font-size: 12px;
  text-align: left;
  cursor: pointer;
}
.select-search--multiple .select-search__option {
  height: 48px;
}
.select-search__option.is-selected {
  background: #2fcc8b;
  color: #fff;
}
.select-search__option.is-highlighted, .select-search__option:not(.is-selected):hover {
  background: rgba(47, 204, 139, 0.1);
}
.select-search__option.is-highlighted.is-selected, .select-search__option.is-selected:hover {
  background: #2eb378;
  color: #fff;
}
/** * Group */
.select-search__group-header {
  font-size: 10px;
  text-transform: uppercase;
  background: #eee;
  padding: 8px 16px;
}
/** * States */
.select-search.is-disabled {
  opacity: 0.5;
}
.select-search.is-loading .select-search__value::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
  background-size: 11px;
}
.select-search:not(.is-disabled) .select-search__input {
  cursor: pointer;
}
/** * Modifiers */
.select-search--multiple {
  border-radius: 3px;
  overflow: hidden;
}
.select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after {
  transform: rotate(45deg);
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  pointer-events: none;
}
.select-search--multiple .select-search__input {
  cursor: initial;
}
.select-search--multiple .select-search__input {
  border-radius: 3px 3px 0 0;
}
.select-search--multiple:not(.select-search--search) .select-search__input {
  cursor: default;
}
.select-search:not(.select-search--multiple) .select-search__input:hover {
  border-color: #2fcc8b;
}
.select-search:not(.select-search--multiple) .select-search__select {
  position: absolute;
  z-index: 2;
  top: 30px;
  right: 0;
  left: 0;
  border-radius: 3px;
  overflow: auto;
  max-height: 370px;
}
.select-search--multiple .select-search__select {
  position: relative;
  overflow: auto;
  max-height: 260px;
  border-top: 1px solid #eee;
  border-radius: 0 0 3px 3px;
}
.select-search__not-found {
  height: auto;
  padding: 16px;
  text-align: center;
  color: #888;
}
.csv-label {
  margin-top: 7px;
  font-size: 16px;
  cursor: pointer;
}
.csv-input {
  display: none;
}
.MuiTableCell-root {
  font-size: 13px !important;
}
.MuiTypography-h6 {
  font-size: 14px !important;
}
.MuiToolbar-root * {
  font-size: 18px !important;
}
.MuiTableCell-footer * {
  font-size: 12px !important;
}
.sidebar-main {
  z-index: 9999;
}
.MuiChip-root {
  height: 22px;
}
.MuiChip-root .MuiChip-label {
  font-size: 14px;
  font-family: Arial;
  line-height: 100%;
}
.MuiChip-root .MuiChip-deleteIcon {
  width: 15px;
  height: 15px;
}
.height32 {
  height: 32px !important;
}
.air-datepicker .MuiInput-underline:before {
  border: 0 !important;
}
.air-datepicker .MuiInputBase-input {
  font-size: 14px;
}
.nice-dates-popover {
  z-index: 9999 !important;
}
.sticky-table-cell {
  border: #e5e5e5 0.5px solid;
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.sidebar-menu-one .sidebar-menu-content .nav-sidebar-menu > .nav-item .sub-group-menu > .nav-item .nav-link {
  padding: 8px 20px 8px 40px;
}
.react-timerange-picker {
  width: 100% !important;
}
.react-timerange-picker__wrapper {
  border: #ccc 1px solid;
  border-radius: 3px;
  width: 100%;
}
.btn input[type="file"] {
  display: none;
}
.liststep {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  margin-bottom: 20px;
}
.liststep .itemstep {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
}
.liststep .itemstep .number {
  color: #687187;
  width: 44px;
  height: 44px;
  border-radius: 22px;
  border: 1px solid #c5cee0;
  padding: 10px;
}
.liststep .itemstep .title {
  color: #c8c8c8;
  padding-top: 5px;
}
.liststep .itemstep.active .title {
  color: #042954;
  font-weight: bold;
}
.liststep .itemstep.active .number {
  background: #042954;
  color: #fff;
  font-weight: bold;
}
.font14 {
  font-size: 14px;
}
.modal.show {
  z-index: 9999;
}
.modal-90w {
  max-width: 85%;
}
.cursor-pointer {
  cursor: pointer;
}
.react-confirm-alert-body > h1 {
  font-size: 24px !important;
  margin-bottom: 10px !important;
}
#react-confirm-alert {
  position: fixed;
  z-index: 99999;
}
